var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.vueProdutos)?_c('v-container',[_c('PageToolbar',{attrs:{"title":"Produtos","icon":"shopping_cart","dark":""}}),_c('v-card',{staticClass:"mt-6"},[_c('v-card-title',{staticClass:"px-6 subtitle-1 lightgray"},[_c('v-text-field',{staticStyle:{"max-width":"400px"},attrs:{"append-icon":"search","label":"Buscar","clearable":"","solo":"","flat":"","outlined":"","dense":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-btn',{staticClass:"toolbar--text ml-6",staticStyle:{"background-color":"#ffffff !important"},attrs:{"outlined":"","height":"40px"},on:{"click":function($event){_vm.display = !_vm.display}}},[_c('v-icon',[_vm._v("filter_list")])],1),_c('v-spacer'),(_vm.vueExportListaProdutos)?[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","depressed":"","small":"","color":"buttons"},on:{"click":_vm.gerarExcelReposicao}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("get_app")]),_vm._v(" Reposições ")],1),_c('v-btn',{attrs:{"dark":"","depressed":"","small":"","color":"buttons2"},on:{"click":_vm.gerarExcel}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("get_app")]),_vm._v(" Excel ")],1)]:_vm._e()],2),_c('Filtro',{attrs:{"P_cidade":"","P_depositos":"","P_produto_classificacao":"","display":_vm.display},on:{"update:display":function($event){_vm.display=$event}}}),_c('v-divider'),_c('v-card-text',[(!_vm.loading)?_c('v-data-table',{staticClass:"data-tables",class:{ 'data-tables__row-click': _vm.vueProduto },attrs:{"headers":_vm.headers,"items":_vm.produtos,"expanded":_vm.expanded,"show-expand":"","search":_vm.search,"items-per-page":-1,"sort-by":['id']},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.goToProduto},scopedSlots:_vm._u([{key:"item.costo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("guarani")(item.costo))+" ")]}},{key:"item.descricao",fn:function(ref){
var item = ref.item;
return [(item.img)?_c('v-tooltip',{staticClass:"pa-1",attrs:{"top":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('div',_vm._g({},on),[_vm._v(" "+_vm._s(item.descricao)+" "),(item.url)?_c('v-btn',{staticClass:"ml-1",attrs:{"href":item.url,"target":"_blank","icon":"","x-small":""}},[_c('v-icon',[_vm._v("link")])],1):_vm._e()],1)]}}],null,true)},[_c('v-card',{attrs:{"max-height":"200px","max-width":"200px"}},[_c('v-img',{attrs:{"src":_vm.apiUrl + item.img}})],1)],1):_c('div',[_vm._v(" "+_vm._s(item.descricao)+" "),(item.url)?_c('v-btn',{staticClass:"ml-1",attrs:{"href":item.url,"target":"_blank","icon":"","x-small":""}},[_c('v-icon',[_vm._v("link")])],1):_vm._e()],1)]}},{key:"item.saldo",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.saldo)+" "+_vm._s(item.und ? item.und : "")+" "),(_vm.saldoAbaixo(item.saldo, item.estoque_min))?_c('v-icon',{attrs:{"color":"error","small":""}},[_vm._v(" arrow_downward ")]):_vm._e(),(_vm.saldoAcima(item.saldo, item.estoque_max))?_c('v-icon',{attrs:{"color":"buttons2","small":""}},[_vm._v(" arrow_upward ")]):_vm._e()]}},{key:"item.caixas",fn:function(ref){
var item = ref.item;
return [(item.qtde_caixa)?_c('div',[_c('v-icon',{attrs:{"small":""}},[_vm._v("legend_toggle")]),_vm._v(" "+_vm._s(_vm._f("number")((item.saldo / item.qtde_caixa)))+" ")],1):_vm._e()]}},{key:"item.precio",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("guarani")(item.precio))+" ")]}},{key:"expanded-item",fn:function(ref){
var item = ref.item;
return [_c('td'),_c('td',{staticClass:"pa-0",attrs:{"colspan":_vm.headers.length - 1}},[_c('v-data-table',{staticClass:"sub-data-tables elevation-0",attrs:{"headers":_vm.depositosHeader,"items":item.depositos,"item-key":"id","hide-default-footer":"","mobile-breakpoint":NaN},scopedSlots:_vm._u([{key:"item.deposito",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.cidade)+" - "+_vm._s(item.deposito)+" ")]}}],null,true)})],1)]}}],null,false,3404469456)}):_c('Carregando')],1)],1),(_vm.vueAddProduto)?_c('v-btn',{class:_vm.$vuetify.breakpoint.mdAndUp ? 'mr-12' : '',attrs:{"to":{ name: 'NovoProduto' },"fixed":"","right":"","bottom":"","fab":"","dark":"","color":"buttons"}},[_c('v-icon',[_vm._v("add")])],1):_vm._e()],1):_c('NaoAutorizado')}
var staticRenderFns = []

export { render, staticRenderFns }