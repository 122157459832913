<template>
  <v-container v-if="vueProdutos">
    <PageToolbar title="Produtos" icon="shopping_cart" dark />

    <v-card class="mt-6">
      <v-card-title class="px-6 subtitle-1 lightgray">
        <v-text-field
          style="max-width: 400px;"
          v-model="search"
          append-icon="search"
          label="Buscar"
          clearable
          solo
          flat
          outlined
          dense
          hide-details
        ></v-text-field>
        <v-btn
          style="background-color: #ffffff !important;"
          outlined
          class="toolbar--text ml-6"
          height="40px"
          @click="display = !display"
        >
          <v-icon>filter_list</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <template v-if="vueExportListaProdutos">
          <v-btn
            dark
            depressed
            small
            color="buttons"
            class="mr-2"
            @click="gerarExcelReposicao"
          >
            <v-icon left>get_app</v-icon>
            Reposições
          </v-btn>
          <v-btn dark depressed small color="buttons2" @click="gerarExcel">
            <v-icon left>get_app</v-icon>
            Excel
          </v-btn>
        </template>
      </v-card-title>
      <Filtro
        P_cidade
        P_depositos
        P_produto_classificacao
        :display.sync="display"
      />
      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          v-if="!loading"
          :headers="headers"
          :items="produtos"
          :expanded.sync="expanded"
          show-expand
          :search="search"
          :items-per-page="-1"
          :sort-by="['id']"
          class="data-tables"
          :class="{ 'data-tables__row-click': vueProduto }"
          @click:row="goToProduto"
        >
          <template v-slot:item.costo="{ item }">
            {{ item.costo | guarani }}
          </template>
          <template v-slot:item.descricao="{ item }">
            <v-tooltip top v-if="item.img" class="pa-1" max-width="200">
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  {{ item.descricao }}
                  <v-btn
                    v-if="item.url"
                    :href="item.url"
                    target="_blank"
                    icon
                    x-small
                    class="ml-1"
                  >
                    <v-icon>link</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-card max-height="200px" max-width="200px">
                <v-img :src="apiUrl + item.img"> </v-img>
              </v-card>
            </v-tooltip>
            <div v-else>
              {{ item.descricao }}
              <v-btn
                v-if="item.url"
                :href="item.url"
                target="_blank"
                icon
                x-small
                class="ml-1"
              >
                <v-icon>link</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:item.saldo="{ item }">
            {{ item.saldo }} {{ item.und ? item.und : "" }}
            <v-icon
              color="error"
              small
              v-if="saldoAbaixo(item.saldo, item.estoque_min)"
            >
              arrow_downward
            </v-icon>
            <v-icon
              color="buttons2"
              small
              v-if="saldoAcima(item.saldo, item.estoque_max)"
            >
              arrow_upward
            </v-icon>
          </template>
          <template v-slot:item.caixas="{ item }">
            <div v-if="item.qtde_caixa">
              <v-icon small>legend_toggle</v-icon>
              {{ (item.saldo / item.qtde_caixa) | number }}
            </div>
          </template>
          <template v-slot:item.precio="{ item }">
            {{ item.precio | guarani }}
          </template>

          <template v-slot:expanded-item="{ item }">
            <td></td>
            <td class="pa-0" :colspan="headers.length - 1">
              <v-data-table
                :headers="depositosHeader"
                :items="item.depositos"
                class="sub-data-tables elevation-0"
                item-key="id"
                hide-default-footer
                :mobile-breakpoint="NaN"
              >
                <template v-slot:item.deposito="{ item }">
                  {{ item.cidade }} - {{ item.deposito }}
                </template>
              </v-data-table>
            </td>
          </template>
        </v-data-table>
        <Carregando v-else />
      </v-card-text>
    </v-card>

    <v-btn
      v-if="vueAddProduto"
      :to="{ name: 'NovoProduto' }"
      fixed
      right
      bottom
      fab
      dark
      color="buttons"
      :class="$vuetify.breakpoint.mdAndUp ? 'mr-12' : ''"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-container>
  <NaoAutorizado v-else />
</template>

<script>
import { getAllProdutos } from "@/services/api/produtos.api.js";
import { excelDownload } from "@/helpers/excel.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaProdutos",
  components: {
    Filtro: () => import("@/components/global/Filtro.vue"),
  },
  props: {},
  data() {
    return {
      display: false,
      produtos: [],
      expanded: [],
      reposicao: [],
      search: "",
      apiUrl: null,
      loading: false,
    };
  },
  computed: {
    headers() {
      return [
        {
          text: "ID",
          value: "id",
        },
        {
          text: "Classificação",
          value: "classificacao",
        },
        {
          text: "Descrição",
          value: "descricao",
        },
        {
          text: "Fornecedor",
          value: "fornecedor",
        },
        {
          text: "Saldo",
          value: "saldo",
        },
        {
          text: "Caixas",
          value: "caixas",
        },
        {
          text: "Preço",
          value: "precio",
        },
        { text: "", value: "data-table-expand" },
      ];
    },
    depositosHeader() {
      return [
        {
          text: "Depositos",
          value: "deposito",
        },
        {
          text: "Saldo",
          align: "right",
          value: "saldo",
        },
      ];
    },
    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      return `?${queryString}`;
    },
    ...mapGetters("Componentes", ["getAccess"]),
    vueProdutos() {
      return this.getAccess("vueProdutos", "menu");
    },
    vueProduto() {
      return this.getAccess("vueProduto", "visualizar");
    },
    vueAddProduto() {
      return this.getAccess("vueProduto", "adicionar");
    },
    vueExportListaProdutos() {
      return this.getAccess("vueListaProdutos", "exportar");
    },
  },
  watch: {
    url() {
      this.getProdutos();
    },
  },
  methods: {
    saldoAbaixo(saldo, min) {
      let result = false;
      if (!this.$route.query.cidade && !this.$route.query.deposito && min) {
        if (saldo <= min) {
          return true;
        }
      }
      return result;
    },
    saldoAcima(saldo, max) {
      let result = false;
      if (!this.$route.query.cidade && !this.$route.query.deposito && max) {
        if (saldo >= max) {
          return true;
        }
      }
      return result;
    },
    goToProduto(item) {
      if (this.vueProduto) {
        this.$store.commit("UPDATE_DYNAMICCRUMB", item.descricao);
        this.$router.push({
          name: "Produto",
          params: { produtoId: item.id },
        });
      }
    },
    getProdutos() {
      this.loading = true;
      getAllProdutos(this.url)
        .then((response) => {
          this.produtos = response;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
        });
    },
    getProdutosReposicao() {
      getAllProdutos("")
        .then((response) => {
          response.forEach((item) => {
            if (
              item.estoque_min &&
              Number(item.saldo) <= Number(item.estoque_min)
            ) {
              item.sugestao = Number(item.estoque_max) - Number(item.saldo);
              item.customedio = this.$options.filters._guarani(item.customedio);
              this.reposicao.push(item);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    gerarExcel() {
      const tHeader = ["ID", "CLASSIFICAÇÃO", "DESCRIÇÃO", "SALDO", "PREÇO"];
      const filterVal = ["id", "classificacao", "descricao", "saldo", "precio"];
      const list = this.produtos;
      list.forEach((item) => {
        item.precio = this.$options.filters.guarani(item.precio);
      });
      const now = this.$moment().format("DD-MM-YYYY");
      const hrs = this.$moment().format("hh");
      const mm = this.$moment().format("mm");
      const ss = this.$moment().format("ss");
      let filtros = "";
      for (let key in this.$route.query) {
        filtros += `-${key}`;
      }
      const filename = `TOCS-Produtos${filtros}_${now}_${hrs}hrs_${mm}m_${ss}s`;
      this.$Progress.start();
      this.loading = true;
      excelDownload(tHeader, filterVal, list, filename).then(() => {
        this.$toast.success("Excel gerado com sucesso!");
        this.$Progress.finish();
        this.loading = false;
      });
    },
    gerarExcelReposicao() {
      const tHeader = [
        "ID",
        "CLASSIFICAÇÃO",
        "DESCRIÇÃO",
        "CUSTO MÉDIO",
        "SALDO ATUAL",
        "SUGESTAO",
        "FORNECEDOR",
        "LINK",
      ];
      const filterVal = [
        "id",
        "classificacao",
        "descricao",
        "customedio",
        "saldo",
        "sugestao",
        "fornecedor",
        "url",
      ];
      const list = this.reposicao;
      const now = this.$moment().format("DD-MM-YYYY");
      const hrs = this.$moment().format("hh");
      const mm = this.$moment().format("mm");
      const ss = this.$moment().format("ss");
      const filename = `TOCS-Produtos-Reposicao_${now}_${hrs}hrs_${mm}m_${ss}s`;
      this.$Progress.start();
      this.loading = true;
      excelDownload(tHeader, filterVal, list, filename).then(() => {
        this.$toast.success("Excel gerado com sucesso!");
        this.$Progress.finish();
        this.loading = false;
      });
    },
  },
  beforeCreate() {
    this.$Progress.start();
  },
  mounted() {
    if (this.vueProdutos) {
      if (process.env.VUE_APP_API_URL) {
        this.apiUrl = process.env.VUE_APP_API_URL;
      }
      this.getProdutos();
      this.getProdutosReposicao();
    }
  },
};
</script>

<style lang="scss" scoped>
::v-deep .v-data-table tbody tr {
  &.v-data-table__expanded__row {
    background: #eeeeee;
    margin-top: 10px;
    td {
      border-top: 0px solid #dddddd;

      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }

  &.v-data-table__expanded__content {
    margin-bottom: 10px;
    box-shadow: none;
    td {
      border-bottom: none;
    }
  }
}

::v-deep .sub-data-tables {
  border-bottom: 0px solid #dddddd;
  thead {
    background: #eeeeee;
    th {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
  tbody {
    td {
      &:first-child {
        border-left: 1px solid #dddddd;
      }
      &:last-child {
        border-right: 1px solid #dddddd;
      }
    }
  }
}
</style>
